import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import Waves from './components/Waves';
import Products from './components/Products';

function App() {

    const mail = "backoffice@mentoringsystems.be";

    const renderDocumentRoutes = () => {
        const data = [
            {
                path: 'algemene-voorwaarden-general-conditions',
                doc: 'documents/MSG_General_Conditions.pdf'
            },
            {
                path: 'privacybeleid',
                doc: 'documents/Privacybeleid_OvereenkomstGegevensgebruik_MentoringSystems_NL-ENG.pdf'
            },
            {
                path: 'gebruiksvoorwaarden',
                doc: 'documents/Gebruiksvoorwaarden_MentoringSystems_NL-ENG.pdf'
            },
            {
                path: 'cookiepolicy',
                doc: 'documents/Cookiebeleid_MentoringSystems_NL-ENG.pdf'
            }
        ];

        const docReroutes = data.map(doc => <Route exact path={[`/${doc.path}`,`/${doc.path}/`]}><Redirect to={`/${doc.doc}`}/></Route>);

        return docReroutes;
    };

    return (
            <Switch>
            <Route exact path="/">
                <div className="App">
                    <Header mail={mail}/>
                    <Main>
                        <Waves>
                            <div className="wrapper coaching">
                                <div className="coaching-block">
                                    <h1 className="main-title">Software</h1>
                                    <div className="main-box">
                                        <img src="software.svg" alt="lightbulb-icon" />
                                        <p>
                                            Wij werken mee aan de ontwikkeling van duurzame
                                            applicaties. Onze kerncompetenties liggen in de
                                            ontwikkeling van hybride apps en interfacing
                                            met derden.
                                        </p>
                                    </div>
                                </div>
                                <div className="coaching-block">
                                    <h1 className="main-title">Coaching</h1>
                                    <div className="main-box">
                                        <img src="lightbulb.svg" alt="lightbulb-icon" />
                                        <p>
                                            Wij coachen bedrijven in het gebruik van lean start-up & agile methodes in combinatie met design thinking voor de ontwikkeling van producten en diensten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Waves>
                        <div className="wrapper products">
                            <h1>Enkele producten waaraan wij werken:</h1>
                            <Products />
                        </div>
                        <div className="wrapper intresse">
                            <div className="intresse-wrapper">
                                <h1>Interesse? </h1>
                                <a className="button big round button-contact" href={`mailto:${mail}`}>contacteer ons</a>
                            </div>
                        </div>
                    </Main>
                    <Footer />
                </div>
            </Route>
            {renderDocumentRoutes()}
            <Route path="/">
                <Redirect to="/" />
            </Route>
            </Switch>
            );
}

export default App;

import React from 'react';

const Header = (props) => {

  const {mail} = props;

  return (
    <header className="wrapper">
      <img src="/logo.png" alt="Mentoringsystems main logo"/>
      <a className="button small round button-contact" href={`mailto:${mail}`}>contacteer ons</a>
    </header>
  )
}

export default Header

import React from 'react';
import { Motion, spring } from "react-motion";

const Waves = (props) => {
    return (
            <div className="waves">
                <Motion
                    defaultStyle={{wrapper1: 5, wrapper2: 7, wrapper3: 10}}
                    style={{
                            wrapper1: spring(0, {stiffness: 2, damping: 3}),
                            wrapper2: spring(0, {stiffness: 2, damping: 2}),
                            wrapper3: spring(0, {stiffness: 2, damping: 1})
                        }}>
                    {interpolatesStyles => (
                                <div className="waves-wrappers">
                                    <div className="waves-wrapper" style={{backgroundPosition: interpolatesStyles.wrapper1 + "%"}}></div>
                                    <div className="waves-wrapper2" style={{backgroundPosition: interpolatesStyles.wrapper2 + "%"}}></div>
                                    <div className="waves-wrapper3" style={{backgroundPosition: interpolatesStyles.wrapper3 + "%"}}></div>
                                </div>
                    )}
                </Motion>
                <div className="waves-wrapper-mobile"></div>
                {props.children}
            </div>
            );
};

export default Waves;

import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="adress">
        <ul className="adress-list">
          <li className="bold">Mentoring Systems BVBA</li>
          <li>Rijselstraat 1</li>
          <li>8200 Brugge</li>
          <li>+32 (0)50 68 30 38</li>
          <li>backoffice(at)mentoringsystems.be</li>
        </ul>
      </div>
      <div className="footer-nav">
        <ul>
          <li><a href="/algemene-voorwaarden-general-conditions" target="_blank" rel="noreferrer">Algemene Voorwaarden</a></li>
          <li><a href="/privacybeleid" target="_blank" rel="noreferrer">Privacybeleid</a></li>
          <li><a href="/gebruiksvoorwaarden" target="_blank" rel="noreferrer">Gebruiksvoorwaarden</a></li>
          <li><a href="/cookiepolicy" target="_blank" rel="noreferrer">Cookiepolicy</a></li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer

import React, {useState, useEffect} from 'react';

const ProductItem = (props) => {

  const {name, imgsrc, url} = props.data;

  return (
    <div className="product">
      <a href={url} target="_blank" rel="noreferrer">
        <div className="product-title">{name}</div>
        <img className="product-image" src={imgsrc} alt={`${name} logo`}/>
      </a>
    </div>
  )
}

const Products = (props) => {


  const [products, setProducts] = useState([
    {
      name: 'Merke',
      imgsrc: 'merke.png',
      url: 'https://info.merke.be'
    },
    {
      name: 'Clicla',
      imgsrc: 'clicla.png',
      url: 'https://clicla.be'
    },
    {
      name: 'JCP Suite',
      imgsrc: 'jcp.png',
      url: 'https://myjcp.de'
    },
    {
      name: 'KeyVance Connect',
      imgsrc: 'keyvance.png',
      url: 'https://connect.keyvance.de/'
    }
  ]);

  const renderProducts = () => {
    return products.map((product, index) => <ProductItem data={product} key={index} />)
  }

  return (
    <div className="products-list">
      {renderProducts()}
    </div>
  )
}

export default Products
